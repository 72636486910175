import { fetchBestPriceForOffer } from 'actions/OfferActions'
import useOffer from 'hooks/Offers/useOffer'
import { useAppDispatch, useAppSelector, shallowEqual } from 'hooks/reduxHooks'
import useBedbankRates from 'hooks/useBedbankRates'
import { isFlightForceBundled } from 'lib/offer/isFlightForceBundled'
import offerPageURL from 'lib/offer/offerPageURL'
import { isBedbankOffer, isHotel, isLEHotel } from 'lib/offer/offerTypes'
import { buildSearchParamsFromFilters } from 'lib/search/searchUtils'
import { useEffect, useMemo } from 'react'
import { HotLeadPriceData, getBedbankPriceData, getLeHotelPriceData } from 'selectors/recomendations/hotLeadSelectors'

type HotLeadSupportedType = App.HotelOffer | App.HotelOfferSummary | App.BedbankOffer | App.BedbankOfferSummary

export interface HotLeadDataView {
  offer: HotLeadSupportedType,
  checkIn?: string,
  checkOut?: string,
  duration: number,
  occupancy: Array<App.Occupants>,
  displayedPrice: number,
  isMemberPrice: boolean,
  discountPercent: number,
  offerUrl: string,
}

// currently we support hotel only
export function isHotLeadSupported(offer: App.AnyOffer): offer is HotLeadSupportedType {
  return isHotel(offer) && !isFlightForceBundled(offer)
}

interface HotLeadData {
  hotLeadData?: HotLeadDataView;
  hotLeadDataReady: boolean;
}

export function useHotLeadData(hotLead:App.HotLeadOffer, isHotLeadEnabled: boolean = true): HotLeadData {
  const dispatch = useAppDispatch()
  const {
    offerId,
    checkIn,
    checkOut,
    occupancy,
  } = hotLead

  const [offer, fetching] = useOffer(offerId)
  const validOffer = !!offer && isHotLeadSupported(offer)
  const isLeHotel = isLEHotel(offer)
  const isBedbankHotel = isBedbankOffer(offer)
  const hasDates = !!checkIn && !!checkOut
  const occupanciesInvalid = (occupancy ?? []).length === 0 || (occupancy ?? []).some(item => item.childrenAge?.some(age => age < 0))
  const validHotLead = (
    isHotLeadEnabled &&
    (fetching || validOffer) &&
    !occupanciesInvalid &&
    (!isBedbankHotel || hasDates) // bedbank HL must also have dates
  )

  // calculate the best price for LE offers (not bedbank)
  useEffect(() => {
    if (isLeHotel && validHotLead && !!offer && hasDates) {
      dispatch(fetchBestPriceForOffer(offer, {
        checkIn,
        checkOut,
        occupants: occupancy,
      }))
    }
  }, [dispatch, checkIn, checkOut, occupancy, hasDates, offer, isLeHotel, validHotLead])

  const leHotelArgs = useMemo(() => ({ offer, validHotLead, hotLead }), [offer, validHotLead, hotLead])
  const leHotelPriceData = useAppSelector<HotLeadPriceData>(
    (state) => getLeHotelPriceData(state, leHotelArgs),
    shallowEqual,
  )

  // calculate bedbank price
  const [{ hotelOnlyRates: bedbankRates }, fetchingBedbankRates] = useBedbankRates(offerId, occupancy, checkIn, checkOut, isBedbankHotel)
  const bedbankArgs = useMemo(() => (
    { offer, validHotLead, bedbankRates, fetchingBedbankRates }
  ), [offer, validHotLead, bedbankRates, fetchingBedbankRates])
  const bedbankPriceData = useAppSelector<HotLeadPriceData>(
    (state) => getBedbankPriceData(state, bedbankArgs),
    shallowEqual,
  )

  const offerUrl = useMemo(() => {
    if (validHotLead && !!offer) {
      const filters = {
        checkIn,
        checkOut,
        rooms: occupancy,
      }
      const queryParams = buildSearchParamsFromFilters(filters)

      return offerPageURL(offer, queryParams)
    }
  }, [checkIn, checkOut, occupancy, offer, validHotLead])

  const {
    totalPrice,
    isMemberPrice,
    discountPercent,
    duration,
    isSoldOut,
  } = isLeHotel ? leHotelPriceData : bedbankPriceData

  const validData = (
    !!offer &&
    !!duration &&
    !!totalPrice &&
    !!offerUrl
  )

  const hotLeadDataReady = !validHotLead || !validOffer || isSoldOut || validData

  const hotLeadData: HotLeadDataView | undefined = (validHotLead && validOffer && validData) ? {
    offer,
    checkIn,
    checkOut,
    duration,
    occupancy,
    displayedPrice: totalPrice,
    isMemberPrice,
    discountPercent,
    offerUrl,
  } : undefined

  return {
    hotLeadData,
    hotLeadDataReady,
  }
}
